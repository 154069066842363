// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import { CButton, CCol, CModal, CModalBody, CRow, CSpinner } from '@coreui/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TagForm } from 'src/components/forms/tagForm'
import { MyRoutes } from 'src/routes'
import { post } from 'src/services/http-service'
import { ENDPOINT_POST_CREATE_TAG } from 'src/variables'

const AddTag = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const userToken = useSelector((state) => state.user.token)
  const [tag, setTag] = useState()
  const [createRequestState, setCreateRequestState] = useState('not_sent')

  const navigate = useNavigate()

  async function submit() {
    // const reqBody = formOutput2CreateBody(tag)
    setCreateRequestState('pending')

    try {
      // console.log(reqBody)
      const res = await post({
        endpoint: ENDPOINT_POST_CREATE_TAG,
        bearerToken: userToken,
        data: {
          order: tag.order,
          logoName: tag.logoName,
          locales: tag.locales,
        },
      })
      setCreateRequestState('success')
    } catch (err) {
      setCreateRequestState('failure')
    }
  }

  // function formOutput2CreateBody(tag) {
  //   // strip non valid id from itinerary body
  //   const { id, ...rest } = tag
  //   let createBody = rest

  //   const bodyFormData = new FormData()
  //   bodyFormData.append('order', createBody.order)
  //   bodyFormData.append('fileImage', createBody.fileImage)
  //   bodyFormData.append('locales', JSON.stringify(createBody.locales))

  //   return bodyFormData
  // }

  // return <ItineraryForm onChange={setItinerary} onSubmit={submit}></ItineraryForm>
  return (
    <>
      <TagForm onChange={setTag} onSubmit={submit} />
      <CModal
        alignment="center"
        className="show position-fixed"
        keyboard={false}
        portal={false}
        visible={createRequestState !== 'not_sent'}
      >
        <CModalBody>
          <CRow className="justify-content-center align-items-center">
            <CCol className="text-center">
              {createRequestState === 'pending' ? (
                <CSpinner />
              ) : createRequestState === 'success' ? (
                <>
                  <p>Tag creato con successo!</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.DASHBOARD)}>
                    Vai alla dashboard
                  </CButton>
                </>
              ) : (
                <>
                  <p>Non é stato possibile creare il tag</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.DASHBOARD)}>
                    Vai alla dashboard
                  </CButton>
                </>
              )}
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>
    </>
  )
}

export default AddTag
