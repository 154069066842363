import { cilPlus, cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormTextarea,
  CFormLabel,
  CRow,
  CImage,
  CSpinner,
} from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import 'simplebar/dist/simplebar.min.css'
import { get } from 'src/services/http-service'
import { ENDPOINT_GET_TAGS, LOCALES } from 'src/variables'
import { v4 } from 'uuid'
import { object } from 'yup'
import { validateItineraryForm, validationMap } from '../formUtils'
import { TranslationForm } from '../translationForm'
import { WaypointFormCreate } from './waypointFormCreate'

export const ItineraryFormCreate = ({ initialValue, onSubmit, onChange, onUpdatePayments, onUpdateNft }) => {
  const userToken = useSelector((state) => state.user.token)
  // const [itinerary, setItinerary] = useState({
  //   id: initialValue?.id ?? -1,
  //   cost: initialValue?.cost ?? -1,
  //   city: initialValue?.city ?? '',
  //   waypoints: initialValue?.waypoints ?? [],
  //   tags: initialValue?.tags ?? [],
  //   locales: initialValue?.locales ?? [],
  // })

  const formId = v4()

  const [availableTags, setAvailableTags] = useState([])
  const [tagsFetchState, setTagsFetchState] = useState('not_fetched')
  const [waypoints, setWaypoints] = useState(initialValue?.waypoints ?? [])
  const [translations, setTranslations] = useState(initialValue?.locales ?? [])
  const [alertMsg, setAlertMsg] = useState('')
  const [tabOpened, setTabOpened] = useState(initialValue?.id ? '' : 'edit')
  const [platformFee, setPlatformFee] = useState(initialValue?.platform_fee ?? 100)
  const [partnerEmail, setPartnerEmail] = useState(initialValue?.partner_email ?? '')
  const [creator, setCreator] = useState(initialValue?.creator?.email)
  const [stripeAccount, setStripeAccount] = useState({
    account: initialValue?.stripe_account ?? '',
    onBoardingComplete: initialValue?.stripe_account_on_boarding_complete ?? false,
  })
  const [nftInfo, setNftInfo] = useState({})
  const [nftTitle, setNftTitle] = useState("")
  const [nftDescription, setNftDescription] = useState("")
  const [nftCover, setNftCover] = useState("")
  const [nftUpdateStatus, setNftUpdateStatus] = useState("pending")
  const resolverSchema = object({
    id: validationMap.number.required(),
    published: validationMap.boolean.required(),
    cost: validationMap.number
      .min(0, 'Il costo deve essere maggiore o uguale a 0')
      .required('Il costo é un campo obbligatorio'),
    city: validationMap.string.required('La cittá é un campo obbligatorio'),
    tags: validationMap.array
      .min(1, "L'itinerario deve avere almeno un tag")
      .required('I tag sono un campo obbligatorio'),
  }).required()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resolverSchema),
    defaultValues: {
      id: initialValue?.id ?? -1,
      published: initialValue?.published ?? false,
      cost: initialValue?.cost ?? -1,
      city: initialValue?.city ?? '',
      tags: initialValue?.tags ?? [],
    },
  })

  const watchFormFields = useWatch({ control })

  useEffect(() => {
    async function fetchTags() {
      setTagsFetchState('pending')
      const res = await get({ endpoint: ENDPOINT_GET_TAGS, bearerToken: userToken })
      if (initialValue?.nft_hash) {
        const nft = await get({ endpoint: `https://gateway.niftyz.tools/open/metadata/${initialValue.nft_hash}` })
        if (!nft.error) {
          setNftInfo({ title: nft.name, description: nft.description.split('- Visit https://niftyz.io/#/nfts/')[0], cover: nft.image })
          setNftTitle(nft.name)
          setNftDescription(nft.description.split('- Visit https://niftyz.io/#/nfts/')[0])
        }
      }
      setNftUpdateStatus("")
      setAvailableTags(res)
      setTagsFetchState('fetched')
    }

    fetchTags()
  }, [])

  // function onTagSelectValueChange(value) {
  //   console.log('ITINERARY - tag selection change', value)
  // }

  const tagSelectOptions = availableTags.map((tag) => ({
    value: tag.id,
    label: tag.locales[0].name,
  }))

  const translationItems = [
    {
      name: 'name',
      label: 'Titolo itinerario',
      type: 'string',
    },
    {
      name: 'description',
      label: 'Descrizione itinerario',
      type: 'string',
    },
    {
      name: 'recap',
      label: 'Riepilogo itinerario',
      type: 'string',
    },
  ]

  useEffect(() => {
    onChange({
      ...watchFormFields,
      waypoints,
      locales: translations,
      platformFee,
      partnerEmail,
      nftTitle,
      nftDescription,
      nftCover,
      nftInfo
    })
  }, [watchFormFields, waypoints, translations, nftTitle, nftDescription, nftCover, nftInfo])

  useEffect(() => {
    if (alertMsg) alert(alertMsg)
  }, [alertMsg])

  function onTranslationFormChange(index, data) {
    // console.log('ITINERARY - translation form change', index, data)
    const newTranslations = [...translations]
    newTranslations[index] = data
    setTranslations(newTranslations)
  }

  function removeTranslation(index) {
    let newTranslations = [...translations]
    newTranslations.splice(index, 1)
    setTranslations(newTranslations)
  }

  function addTranslation() {
    if (translations.length < LOCALES.length) {
      // console.log('ITINERARY - adding new translation')
      setTranslations([...translations, {}])
    }
  }
  function addWaypoint() {
    // console.log('ITINERARY - adding new waypoint')
    setWaypoints([...waypoints, null])
  }

  function onWaypointChange(index, data) {
    // console.log('ITINERARY - waypoint submit', index, data)
    const newWaypoints = [...waypoints]
    newWaypoints[index] = data
    setWaypoints(newWaypoints)
  }

  function removeWaypoint(index) {
    let newWaypoints = [...waypoints]
    newWaypoints.splice(index, 1)
    setWaypoints(newWaypoints)
  }

  function submitItineraryForm(v) {
    const validationRes = validateItineraryForm({
      ...v,
      locales: translations,
      waypoints,
    })

    // console.log({
    //   ...v,
    //   locales: translations,
    //   waypoints,
    // })
    // console.log(validationRes)

    if (!validationRes) {
      onSubmit({
        ...v,
        locales: translations,
        waypoints,
        platformFee,
        partnerEmail,
        nftTitle,
        nftDescription,
        nftCover,
        nftInfo
      })
    } else {
      setAlertMsg(validationRes)
    }
  }

  const platformFeeUpdate = (event) => {
    setPlatformFee(event.target.value)
  }

  const updatePayments = () => {
    if (
      platformFee >= 0 &&
      platformFee <= 100 &&
      !isNaN(platformFee) &&
      (platformFee.toString().split('.')[1] == null ||
        platformFee.toString().split('.')[1].length <= 2)
    ) {
      onUpdatePayments({
        platformFee,
        partnerEmail,
      })
    }
  }

  const updatePartnerEmail = (e) => {
    setPartnerEmail(e.target.value)
  }

  const removeOriginalNftImg = () => {
    let oldInfo = nftInfo
    setNftInfo({ title: oldInfo.title, description: oldInfo.description })
  }

  const updateNft = async () => {
    if (nftTitle && nftDescription && (nftCover.name || nftInfo.cover)) {
      setNftUpdateStatus("")
      onUpdateNft({
        nftTitle,
        nftDescription,
        nftCover,
        nftInfo
      })
    } else {
      setNftUpdateStatus("missing_fields")
    }
  }

  const removeNftCover = () => {
    setNftCover({})
    document.getElementById('nft_field_cover').value = ''
  }

  const translationsAccordionItems = translations.map((translation, index) => (
    <CAccordionItem key={index}>
      <CAccordionHeader>
        {translation?.locale ? `${index} - ${translation?.locale}` : `Traduzione n.${index}`}
      </CAccordionHeader>
      <CAccordionBody>
        <CButton
          color="danger"
          variant="outline"
          className="accordion-remove-button"
          onClick={() => removeTranslation(index)}
        >
          <CIcon icon={cilTrash} />
        </CButton>
        <TranslationForm
          locales={LOCALES}
          defaultLocale={LOCALES[index]}
          initialValue={translation}
          items={translationItems}
          onChange={(data) => onTranslationFormChange(index, data)}
        ></TranslationForm>
      </CAccordionBody>
    </CAccordionItem>
  ))
  const waypointsAccordionItems = waypoints.map((waypoint, index) => (
    <CAccordionItem key={index}>
      <CAccordionHeader>
        {waypoint?.name ? `${index} - ${waypoint?.name}` : `Tappa n.${index}`}
      </CAccordionHeader>
      <CAccordionBody>
        <CButton
          color="danger"
          variant="outline"
          className="accordion-remove-button"
          onClick={() => removeWaypoint(index)}
        >
          <CIcon icon={cilTrash} />
        </CButton>
        <WaypointFormCreate
          initialValue={waypoint}
          onChange={(data) => onWaypointChange(index, data)}
        />
      </CAccordionBody>
    </CAccordionItem>
  ))

  return (
    <>
      <div style={{ margin: '5rem auto' }}>
        {nftUpdateStatus === 'pending' &&
          <CRow>
            <CSpinner />
          </CRow>
        }
        {nftUpdateStatus !== "pending" &&
          <CRow>
            <h2
              style={{ position: 'relative', cursor: 'pointer' }}
              onClick={() => {
                setTabOpened(tabOpened === 'nft' ? '' : 'nft')
              }}
            >
              NFT
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-caret-down-fill ms-2"
                  viewBox="0 0 16 16"
                  style={{
                    transform: tabOpened === 'nft' ? 'rotateZ(180deg)' : 'rotateZ(0)',
                  }}
                >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg>
              </span>
            </h2>
            {tabOpened === 'nft' && (
              <CRow>
                <CCol className="col-12 mb-3">
                  <CFormLabel>Titolo NFT*</CFormLabel>
                  <CFormInput
                    value={nftTitle}
                    onChange={(event) => { setNftTitle(event.target.value) }}
                    type="text"
                  />
                </CCol>
                <CCol className="col-12 mb-3">
                  <CFormLabel>Descrizione*</CFormLabel>
                  <CFormTextarea
                    onChange={(event) => { setNftDescription(event.target.value) }}
                    value={nftDescription}
                  />
                </CCol>
                <CCol className="col-12">
                  <CFormLabel>Immagine NFT*</CFormLabel>
                  <CFormInput
                    onChange={(event) => {
                      setNftCover(event.target.files[0])
                    }}
                    type="file"
                    files={[nftCover]}
                    id="nft_field_cover"
                  />
                </CCol>
                {nftCover && nftCover.name &&
                  <CCol className="col-12 col-md-7 text-center mt-3 mx-auto position-relative">
                    <CImage className="mx-auto col-11 bg-dark" src={URL.createObjectURL(nftCover)} />
                    <CButton
                      className="btn btn-secondary"
                      style={{ width: '3rem', position: "absolute", top: "0", right: "0", boxShadow: "1px 2px 8px rgba(0,0,0,.09)" }}
                      onClick={removeNftCover}
                    >
                      <CIcon style={{ scale: "1.1" }} icon={cilTrash} />
                    </CButton>
                  </CCol>
                }
                {(!nftCover || !nftCover.name) && nftInfo.cover &&
                  <CCol className="col-12 col-md-7 text-center mt-3 mx-auto position-relative">
                    <CImage className="mx-auto col-11 bg-dark" src={nftInfo.cover} />
                    <CButton
                      className="btn btn-secondary"
                      style={{ width: '3rem', position: "absolute", top: "0", right: "0", boxShadow: "1px 2px 8px rgba(0,0,0,.09)" }}
                      onClick={removeOriginalNftImg}
                    >
                      <CIcon style={{ scale: "1.1" }} icon={cilTrash} />
                    </CButton>
                  </CCol>
                }
                <div>
                  {nftUpdateStatus === 'missing_fields' && (
                    <p className="text-danger mt-2">
                      Alcuni campi obbligatori non sono stati compilati
                    </p>
                  )}
                  {initialValue?.id && (
                    <CButton
                      className="itinerary-form-submit-button mt-3"
                      style={{ width: '5rem' }}
                      onClick={updateNft}
                      disabled={nftInfo.title === nftTitle && nftInfo.description === nftDescription && nftInfo.cover && !nftCover.name}
                    >
                      Salva
                    </CButton>
                  )}
                </div>
              </CRow>
            )}
          </CRow>
        }
      </div>
      <div style={{ margin: '5rem auto' }}>
        <CRow>
          <h2
            style={{ position: 'relative', cursor: 'pointer' }}
            onClick={() => {
              setTabOpened(tabOpened === 'payments' ? '' : 'payments')
            }}
          >
            Pagamenti
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-caret-down-fill ms-2"
                viewBox="0 0 16 16"
                style={{
                  transform: tabOpened === 'payments' ? 'rotateZ(180deg)' : 'rotateZ(0)',
                }}
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </span>
          </h2>
          {tabOpened === 'payments' && (
            <CRow>
              {initialValue?.id && !stripeAccount.account && !stripeAccount.onBoardingComplete && (
                <CRow>
                  <p style={{ color: '#e0a700' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#e0a700"
                      className="bi bi-info-circle-fill me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                    </svg>
                    Nessun account Stripe è stato ancora collegato
                  </p>
                </CRow>
              )}
              {initialValue?.id && stripeAccount.account && !stripeAccount.onBoardingComplete && (
                <CRow>
                  <p style={{ color: '#e0a700' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#e0a700"
                      className="bi bi-info-circle-fill me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                    </svg>
                    {`L'utente partner sta collegando un account Stripe`}
                  </p>
                </CRow>
              )}
              {initialValue?.id && stripeAccount.onBoardingComplete && (
                <CRow>
                  <p style={{ color: 'rgb(76 153 0)' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="rgb(76 153 0)"
                      className="bi bi-check-circle-fill me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    {`L'utente partner ha collegato un account Stripe`}
                  </p>
                </CRow>
              )}
              {creator !== partnerEmail && (
                <CCol className="col-md-7 col-12 mb-3">
                  <CFormLabel htmlFor="email-field">Email partner</CFormLabel>
                  <CFormInput
                    value={partnerEmail}
                    onChange={(event) => {
                      updatePartnerEmail(event)
                    }}
                    disabled={stripeAccount?.account ? true : false}
                    id="email-field"
                    type="email"
                  />
                </CCol>
              )}
              {creator === partnerEmail && (
                <p>Questo itinerario è stato creato da <b>{creator}</b></p>
              )}
              <CCol className="col-6">
                <CFormLabel htmlFor="cost-field">Percentuale da trattenere (%)</CFormLabel>
                <CFormInput
                  value={platformFee}
                  min={0}
                  max={100}
                  onChange={(event) => {
                    platformFeeUpdate(event)
                  }}
                  id="cost-field"
                  type="number"
                />
              </CCol>
              <CCol className="col-6">
                <CFormLabel htmlFor="cost-field">Percentuale da inviare (%)</CFormLabel>
                <CFormInput
                  value={platformFee > 100 ? '' : Number(100 - platformFee).toFixed(2)}
                  id="cost-field"
                  readOnly
                  type="number"
                  step={'0.01'}
                />
              </CCol>
              <div>
                {(platformFee < 0 || platformFee > 100) && (
                  <p className="text-danger mt-2">
                    La percentuale deve essere compresa tra 0 e 100
                  </p>
                )}
                {(isNaN(platformFee) || platformFee.toString().split('.')[1]?.length > 2) && (
                  <p className="text-danger mt-2">La percentuale non è valida</p>
                )}
                {initialValue?.id && (
                  <CButton
                    className="itinerary-form-submit-button"
                    style={{ width: '5rem' }}
                    onClick={updatePayments}
                    disabled={
                      platformFee < 0 ||
                      platformFee > 100 ||
                      isNaN(platformFee) ||
                      platformFee.toString().split('.')[1]?.length > 2
                    }
                  >
                    Salva
                  </CButton>
                )}
              </div>
            </CRow>
          )}
        </CRow>
        {/* <CCol xs={12}>
                    <span>{errors[field.name]?.message}</span>
                  </CCol> */}
      </div>
      <h2
        style={{ position: 'relative', cursor: 'pointer' }}
        onClick={() => {
          setTabOpened(tabOpened === 'edit' ? '' : 'edit')
        }}
      >
        Itinerario
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-caret-down-fill ms-2"
            viewBox="0 0 16 16"
            style={{
              transform: tabOpened === 'edit' ? 'rotateZ(180deg)' : 'rotateZ(0)',
            }}
          >
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
          </svg>
        </span>
      </h2>
      {tabOpened === 'edit' && (
        <div>
          <CForm id={formId} onSubmit={handleSubmit(submitItineraryForm)}>
            <Controller
              render={({ field }) => {
                return (
                  <>
                    <CFormLabel htmlFor="published-field" className="me-2">
                      Pubblicato
                    </CFormLabel>
                    <CFormCheck
                      checked={field.value}
                      id="published-field"
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                    <CCol xs={12}>
                      <span>{errors[field.name]?.message}</span>
                    </CCol>
                  </>
                )
              }}
              name="published"
              control={control}
            />
            <Controller
              render={({ field }) => {
                return (
                  <>
                    <CFormLabel htmlFor="cost-field">Costo</CFormLabel>
                    <CFormInput
                      defaultValue={field.value}
                      id="cost-field"
                      type="number"
                      onChange={field.onChange}
                    />
                    <CCol xs={12}>
                      <span>{errors[field.name]?.message}</span>
                    </CCol>
                  </>
                )
              }}
              name="cost"
              control={control}
            />
            <Controller
              render={({ field }) => {
                return (
                  <>
                    <CFormLabel htmlFor="city-field">Cittá</CFormLabel>
                    <CFormInput
                      defaultValue={field.value}
                      id="city-field"
                      type="string"
                      onChange={field.onChange}
                    />
                    <CCol xs={12}>
                      <span>{errors[field.name]?.message}</span>
                    </CCol>
                  </>
                )
              }}
              name="city"
              control={control}
            />
            <Controller
              render={({ field }) => {
                return (
                  <>
                    <span>Tags</span>
                    {tagsFetchState === 'fetched' && (
                      <Select
                        isMulti
                        defaultValue={field.value}
                        options={tagSelectOptions}
                        onChange={field.onChange}
                      />
                    )}
                    <CCol xs={12}>
                      <span>{errors[field.name]?.message}</span>
                    </CCol>
                  </>
                )
              }}
              name="tags"
              control={control}
            />
          </CForm>
          <h4>Traduzioni itinerario</h4>
          <CButton onClick={addTranslation} className="form-add-subitem-button">
            <CIcon icon={cilPlus} /> Aggiungi traduzione
          </CButton>
          <CAccordion>{translationsAccordionItems}</CAccordion>
          <h4>Tappe</h4>
          <CButton className="form-add-subitem-button" onClick={addWaypoint}>
            <CIcon icon={cilPlus} /> Aggiungi tappa
          </CButton>
          <CAccordion>{waypointsAccordionItems}</CAccordion>
          {onSubmit && (
            <CButton className="itinerary-form-submit-button" form={formId} type="submit">
              Salva
            </CButton>
          )}
        </div>
      )}
    </>
  )
}

ItineraryFormCreate.propTypes = {
  initialValue: PropTypes.object,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onUpdatePayments: PropTypes.func,
  onUpdateNft: PropTypes.func
}
