// URLS
export const API_DOMAIN = 'https://api.zonzoapp.com'
// export const API_DOMAIN = 'http://localhost:3000'
export const ENDPOINT_LOGIN = `${API_DOMAIN}/auth/admin-login`
export const ENDPOINT_VALIDATE_TOKEN = `${API_DOMAIN}/auth/validate-token`
export const ENDPOINT_SIGNUP = `${API_DOMAIN}/auth/admin-signup`
export const ENDPOINT_GET_USERS = `${API_DOMAIN}/users/all`
export const ENDPOINT_GET_EXPORT_USERS_CSV = `${API_DOMAIN}/users/export-csv`
export const ENDPOINT_GET_ITINERARIES = `${API_DOMAIN}/itineraries/all`
export const ENDPOINT_GET_ITINERARY_FULL = `${API_DOMAIN}/itineraries/get-full`
export const ENDPOINT_GET_ITINERARY_COMPLETE = `${API_DOMAIN}/itineraries/get-complete`
export const ENDPOINT_GET_TAGS = `${API_DOMAIN}/tags/all`
export const ENDPOINT_GET_MERCHANT_INVITATIONS = `${API_DOMAIN}/merchant/get-invitations`
export const ENDPOINT_GET_MERCHANTS = `${API_DOMAIN}/merchant/get`
export const ENDPOINT_GET_EXPORT_MERCHANTS_CSV = `${API_DOMAIN}/merchant/export-csv`
export const ENDPOINT_POST_SEND_MERCHANT_INVITATION = `${API_DOMAIN}/merchant/send-invitation`
export const ENDPOINT_DELETE_TAG = `${API_DOMAIN}/tags/delete`
export const ENDPOINT_GET_REDEEM_CODES = `${API_DOMAIN}/discountToken/getAll`
export const ENDPOINT_GET_SCORES = `${API_DOMAIN}/scores/all`
export const ENDPOINT_POST_SYNC_BALANCES = `${API_DOMAIN}/wallet/balance/sync-all`
export const ENDPOINT_POST_BURN_TOKENS = `${API_DOMAIN}/wallet/burn`
export const ENDPOINT_POST_SEND_REFERRAL = `${API_DOMAIN}/discountToken/sendTokenQR`
export const ENDPOINT_POST_CREATE_REFERRALS = `${API_DOMAIN}/discountToken/createMany`
export const ENDPOINT_PATCH_UPDATE_REFERRALS_SENT_STATUS = `${API_DOMAIN}/discountToken/update-sent-status`
export const ENDPOINT_POST_CREATE_MERCHANT_INVITATIONS = `${API_DOMAIN}/merchant/create-invitations`
export const ENDPOINT_POST_CREATE_ITINERARY = `${API_DOMAIN}/itineraries/create`
export const ENDPOINT_PATCH_UPDATE_ITINERARY = `${API_DOMAIN}/itineraries/update`
export const ENDPOINT_PATCH_UPDATE_ITINERARY_NFT = `${API_DOMAIN}/itineraries/update/:id/nft`
export const ENDPOINT_POST_CREATE_TAG = `${API_DOMAIN}/tags/create`
export const ENDPOINT_PATCH_UPDATE_TAG = `${API_DOMAIN}/tags/update`
export const ENDPOINT_GET_TAG_COMPLETE = `${API_DOMAIN}/tags/by-id`
export const ENDPOINT_POST_UPLOAD_GAME_IMAGE = `${API_DOMAIN}/uploads/upload-image`
export const ENDPOINT_DELETE_GAME_IMAGE = `${API_DOMAIN}/uploads/delete-image`
export const ENDPOINT_GET_GAME_IMAGES_NAMES = `${API_DOMAIN}/uploads/images-names`
export const ENDPOINT_DELETE_ITINERARY = `${API_DOMAIN}/itineraries/delete`

// PUBLIC
export const GAME_IMAGES_PATH = `${API_DOMAIN}/gameImages`

// STORAGE
export const AUTH_TOKEN_KEY = 'authToken'

// LOCALIZATION
export const LOCALES = ['it', 'en']

// GAMES
export const GAME_TYPES = [
  {
    value: 'riddle',
    label: 'Indovinello',
  },
  {
    value: 'singleChoiceQuiz',
    label: 'Quiz a risposta singola',
  },
  {
    value: 'multipleChoiceQuiz',
    label: 'Quiz a risposta multipla',
  },
  {
    value: 'timedQuiz',
    label: 'Quiz a tempo',
  },
  {
    value: 'photoQuiz',
    label: 'Quiz fotografico',
  },
  {
    value: 'drawingsAndWords',
    label: 'Disegni e parole',
  },
  {
    value: 'rebus',
    label: 'Rebus',
  },
  {
    value: 'hangman',
    label: 'Impiccato',
  },
  {
    value: 'reorderItems',
    label: 'Riordina gli elementi',
  },
  {
    value: 'reorderLetters',
    label: 'Riordina le lettere',
  },
]
