import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ENDPOINT_GET_MERCHANT_INVITATIONS,
  ENDPOINT_POST_SEND_MERCHANT_INVITATION,
  ENDPOINT_POST_SEND_REFERRAL,
} from 'src/variables'
import { get, post, postUnwrapped } from 'src/services/http-service'
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { object, string } from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CIcon from '@coreui/icons-react'
import { cilAt } from '@coreui/icons'

const InvitationsTab = () => {
  const userToken = useSelector((state) => state.user.token)
  const [invitations, setInvitations] = useState([])
  const [selectedInvitation, setSelectedInvitation] = useState()
  const [InvitationDetailsOpen, setInvitationDetailsOpen] = useState(false)
  const [sendInvitationRequestStatus, setSendReferralRequestStatus] = useState('not_sent')
  const [error, setError] = useState(false)

  const resolverSchema = object({
    email: string()
      .required(`L'email é un campo obbligatorio`)
      .email(`L'email deve essere nel formato corretto`),
  }).required()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resolverSchema) })

  useEffect(() => {
    fetchInvitations()
  }, [])

  useEffect(() => {
    if (InvitationDetailsOpen) {
      setSendReferralRequestStatus('not_sent')
    }
  }, [InvitationDetailsOpen])

  async function fetchInvitations() {
    const res = await get({
      endpoint: ENDPOINT_GET_MERCHANT_INVITATIONS,
      bearerToken: userToken,
    })

    console.log(res)

    if (!res.error) {
      setInvitations(res.invitations)
    } else {
      setError(res.msg)
    }
  }

  const submit = async (e) => {
    setSendReferralRequestStatus('pending')
    try {
      const res = await post({
        endpoint: ENDPOINT_POST_SEND_MERCHANT_INVITATION,
        bearerToken: userToken,
        data: {
          tokenValue: selectedInvitation.value,
          recipient: e.email,
        },
      })
      setSendReferralRequestStatus('success')
    } catch (err) {
      setSendReferralRequestStatus('failure')
    }
  }

  return (
    <>
      <CTable striped hover bordered color="light">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Valore</CTableHeaderCell>
            <CTableHeaderCell scope="col">Disponibile</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {invitations.map((el, index) => (
            <CTableRow
              onClick={() => {
                if (!el.consumed) {
                  setSelectedInvitation(el)
                  setInvitationDetailsOpen(true)
                }
              }}
              color={el.consumed ? 'danger' : undefined}
              key={index}
            >
              <CTableHeaderCell scope="row">{el.value}</CTableHeaderCell>
              <CTableDataCell>{!el.consumed ? 'SÍ' : 'NO'}</CTableDataCell>
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
      {selectedInvitation && (
        <CModal
          keyboard={false}
          visible={InvitationDetailsOpen}
          onClose={() => setInvitationDetailsOpen(false)}
          alignment="center"
        >
          <CModalHeader>
            <CModalTitle>Dettagli</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CContainer>
              <CRow>
                <CCol sm={3}>Valore:</CCol>
                <CCol sm={9}>{selectedInvitation.value}</CCol>
              </CRow>
              <CRow>
                <CCol sm={3}>Disponibile:</CCol>
                <CCol sm={9}>{!selectedInvitation.consumed ? 'SÍ' : 'NO'}</CCol>
              </CRow>
              {/* <CRow className="justify-content-center">
                <CCol sm="auto">
                  <QRCode level="M" value={selectedInvitation.value} />
                </CCol>
              </CRow> */}
              <CRow className="mt-3">
                <CCol sm={12}>
                  <h3>Invia codice di invito</h3>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={12}>
                  <CForm onSubmit={handleSubmit(submit)}>
                    <CInputGroup>
                      <CInputGroupText>
                        <CIcon icon={cilAt} />
                      </CInputGroupText>

                      <Controller
                        render={({ field }) => {
                          return (
                            <>
                              <CFormInput
                                placeholder="Email"
                                autoComplete="email"
                                onChange={field.onChange}
                              />
                              <CCol xs={12}>
                                <span>{errors[field.name]?.message}</span>
                              </CCol>
                            </>
                          )
                        }}
                        name="email"
                        control={control}
                      />
                    </CInputGroup>
                    {sendInvitationRequestStatus === 'not_sent' && (
                      <CButton type="submit" className="w-50 mt-3 mx-auto d-block">
                        Invia
                      </CButton>
                    )}
                    {sendInvitationRequestStatus === 'pending' && (
                      <div className="w-100 mt-3 d-flex justify-content-center">
                        <CSpinner />
                      </div>
                    )}
                    {sendInvitationRequestStatus === 'success' && (
                      <p className="w-100 mt-3 text-center">Codice inviato con successo</p>
                    )}
                    {sendInvitationRequestStatus === 'failure' && (
                      <p className="w-100 mt-3 text-center">{`Errore durante l'invio del codice, chiudi il pannello e riprova`}</p>
                    )}
                  </CForm>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm={12}></CCol>
              </CRow>
            </CContainer>
          </CModalBody>
        </CModal>
      )}
    </>
  )
}

export default InvitationsTab
