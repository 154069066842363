import {
  CAlert,
  CButton,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { get, postUnwrapped } from 'src/services/http-service'
import {
  ENDPOINT_GET_EXPORT_MERCHANTS_CSV,
  ENDPOINT_GET_MERCHANTS,
  ENDPOINT_POST_SYNC_BALANCES,
} from 'src/variables'

const MerchantsTab = () => {
  const userToken = useSelector((state) => state.user.token)
  const [fetchMerchantsRequestState, setFetchMerchantsRequestState] = useState('not_sent')
  const [merchants, setMerchants] = useState([])
  const [error, setError] = useState(false)
  const [exportRequestState, setExportRequestState] = useState('not_sent')
  const [syncRequestState, setSyncRequestState] = useState('not_sent')
  const [syncAlertOpen, setSyncAlertOpen] = useState(false)
  const [exportAlertOpen, setExportAlertOpen] = useState(false)

  useEffect(() => {
    fetchMerchants()
  }, [])

  async function fetchMerchants() {
    setFetchMerchantsRequestState('pending')
    const res = await get({
      endpoint: ENDPOINT_GET_MERCHANTS,
      bearerToken: userToken,
    })

    console.log(res)

    if (!res.error) {
      setFetchMerchantsRequestState('success')
      setMerchants(res.merchants)
    } else {
      setFetchMerchantsRequestState('failure')
      setError(res.msg)
    }
  }

  const exportCVS = async () => {
    try {
      setExportRequestState('pending')
      // setExportAlertOpen(true)
      // setTimeout(() => {
      //   setExportAlertOpen(false)
      // }, 5000)
      const res = await get({
        endpoint: ENDPOINT_GET_EXPORT_MERCHANTS_CSV,
        bearerToken: userToken,
      })

      const generatedBlob = new Blob([res], { type: 'text/plain;charset=utf-8' })
      FileSaver.saveAs(generatedBlob, 'exported-users.csv')
      setExportRequestState('success')
    } catch (err) {
      setExportRequestState('failure')
      alert('Esportazione fallita:', err?.message ?? err)
    }
  }

  const syncBalances = async () => {
    try {
      setSyncRequestState('pending')
      setSyncAlertOpen(true)
      setTimeout(() => {
        setSyncAlertOpen(false)
      }, 5000)
      const res = await postUnwrapped({
        endpoint: ENDPOINT_POST_SYNC_BALANCES,
        bearerToken: userToken,
      })
      setSyncRequestState('success')
      if (res.status === 202) {
        alert('Sincronizzazione in corso, potrebbero volerci alcuni minuti.')
      } else {
        alert('Sincronizzazione completata')
        window.location.reload()
      }
    } catch (err) {
      setSyncRequestState('failure')
    }
  }

  const table = (
    <>
      <CButton
        className="w-100 mb-3 mt-4"
        disabled={exportRequestState === 'pending'}
        onClick={() => exportCVS()}
      >
        Esporta CSV
      </CButton>
      <CButton
        className="w-100 mb-3"
        disabled={syncRequestState === 'pending'}
        onClick={() => syncBalances()}
      >
        Sincronizza bilanci
      </CButton>
      <CTable striped hover bordered color="light">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Username</CTableHeaderCell>
            <CTableHeaderCell scope="col">Email</CTableHeaderCell>
            <CTableHeaderCell scope="col">Bilancio</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {merchants.map((el, index) => (
            <CTableRow key={index}>
              <CTableHeaderCell scope="row">{el.user.username}</CTableHeaderCell>
              <CTableDataCell>{el.user.email}</CTableDataCell>
              <CTableDataCell>{el.user.balance}</CTableDataCell>
            </CTableRow>
          ))}
        </CTableBody>
      </CTable>
      <CAlert className="sync-alert w-75" color="info" visible={syncAlertOpen}>
        Sincronizzazione avviata, il processo potrebbe richiedere alcuni minuti
      </CAlert>
    </>
  )

  return (
    <>
      {fetchMerchantsRequestState === 'success' || fetchMerchantsRequestState === 'not_sent' ? (
        table
      ) : fetchMerchantsRequestState === 'failure' ? (
        <p className="w-100 mt-3 text-center">{error}</p>
      ) : (
        <CSpinner />
      )}
    </>
  )
}

export default MerchantsTab
