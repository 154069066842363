// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import { CButton, CCol, CModal, CModalBody, CRow, CSpinner } from '@coreui/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MyRoutes } from 'src/routes'
import { patch, post } from 'src/services/http-service'
import { ENDPOINT_PATCH_UPDATE_ITINERARY_NFT, ENDPOINT_POST_CREATE_ITINERARY } from 'src/variables'
import { ItineraryFormCreate } from 'src/components/forms/create/itineraryFormCreate'
import { GameFields } from 'src/components/forms/gameFields'
import axios from 'axios'

const AddItinerary = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const userToken = useSelector((state) => state.user.token)
  const [itinerary, setItinerary] = useState()
  const [createRequestState, setCreateRequestState] = useState('not_sent')

  const navigate = useNavigate()

  // useEffect(() => {
  //   async function sendItineraryCreateRequest(body) {
  //     console.log(body)
  //     const res = await post({
  //       endpoint: ENDPOINT_POST_CREATE_ITINERARY,
  //       bearerToken: userToken,
  //       data: body,
  //     })

  //     console.log(res)
  //   }

  //   if (itinerary) {
  //     const reqBody = formOutput2CreateBody(itinerary)
  //     sendItineraryCreateRequest(reqBody)
  //   }
  // }, [itinerary])

  async function submit() {
    const reqBody = formOutput2CreateBody(itinerary)
    setCreateRequestState('pending')
    reqBody.platform_fee = reqBody.platformFee
    reqBody.partner_email = reqBody.partnerEmail
    let nftForm = {
      title: reqBody.nftTitle,
      description: reqBody.nftDescription,
      cover: reqBody.nftCover
    }
    if ((nftForm.title || nftForm.description || nftForm.cover.name) && (!nftForm.title || !nftForm.description || !nftForm.cover.name)) {
      alert("Per aggiungere un nft devi compilare il titolo, la descrizione ed inserire un'immagine")
      setCreateRequestState('not_sent')
      return;
    }
    try {
      const res = await post({
        endpoint: ENDPOINT_POST_CREATE_ITINERARY,
        bearerToken: userToken,
        data: reqBody,
      })

      // update itinerary nft immediately after creation
      if (nftForm.cover.name) {
        try {
          // upload cover on ipfs
          console.log("uplaoding on ipfs...")
          const ext = nftForm.cover.name.split(".")[nftForm.cover.name.split(".").length - 1];
          const supported = ["png", "jpg", "jpeg", "gif"];
          if (supported.indexOf(ext) !== -1) {
            const formData = new FormData();
            formData.append("file", nftForm.cover);
            formData.append("name", nftForm.cover.name);
            try {
              let ipfsImageUpload = await axios({
                method: "post",
                url: "https://api.umi.tools/ipfs/upload",
                data: formData,
                headers: {
                  "Content-Type": "multipart/form-data",
                }
              });
              if (ipfsImageUpload.data.error === false) {
                nftForm.cover = "https://ipfs.niftyz.io/ipfs/" + ipfsImageUpload.data.ipfs_hash
              } else {
                setCreateRequestState('not_sent')
                return;
              }
            }
            catch (e) {
              console.log(e)
              setCreateRequestState('not_sent')
              return;
            }
          } else {
            setCreateRequestState('not_sent')
            alert("Estensione non valida per l'immagine dell'nft")
            return;
          }

          await patch({
            endpoint: ENDPOINT_PATCH_UPDATE_ITINERARY_NFT.split(':id').join(res.id),
            bearerToken: userToken,
            data: {
              title: nftForm.title,
              description: nftForm.description,
              cover: nftForm.cover,
            }
          })

          setCreateRequestState('success')
        } catch { setCreateRequestState('not_sent') }
      } else {
        setCreateRequestState('success')
      }
    } catch {
      setCreateRequestState('not_sent')
    }
  }

  function gameConfiguration2Record(configuration, gameType) {
    const { title, description, hint, locale, isDefault, ...config } = configuration

    return {
      title,
      description,
      hint,
      config: JSON.stringify(
        GameFields[gameType].reduce(
          (acc, field) => ({
            ...acc,
            [field.name]: field.name === 'reward' ? Number(config[field.name]) : config[field.name],
          }),
          {},
        ),
      ),
      locale,
      isDefault,
    }
  }

  function formOutput2CreateBody(itinerary) {
    // strip non valid id from itinerary body
    const { id, ...rest } = itinerary
    let createBody = rest
    // get tags id and strip the label
    createBody.tags = createBody.tags.map((tag) => tag.value)
    createBody.waypoints = createBody.waypoints.map((waypoint) => {
      // strip non valid id from waypoint body
      const { waypointId: id, ...waypointRest } = waypoint
      let waypointBody = waypointRest
      waypointBody.games = waypointBody.games.map((game) => {
        // strip non valid id from game body
        const { gameId: id, ...gameRest } = game
        gameRest.locales = gameRest.locales.map((locale) =>
          gameConfiguration2Record(locale, gameRest.type),
        )
        return gameRest
      })
      return waypointRest
    })

    createBody = {
      ...createBody,
      locales: JSON.stringify(createBody.locales),
      waypoints: JSON.stringify(createBody.waypoints),
    }

    return createBody
  }

  return (
    <>
      <ItineraryFormCreate onChange={setItinerary} onSubmit={submit}></ItineraryFormCreate>
      <CModal
        alignment="center"
        className="show position-fixed"
        keyboard={false}
        portal={false}
        visible={createRequestState !== 'not_sent'}
      >
        <CModalBody>
          <CRow className="justify-content-center align-items-center">
            <CCol className="text-center">
              {createRequestState === 'pending' ? (
                <CSpinner />
              ) : createRequestState === 'success' ? (
                <>
                  <p>Itinerario creato con successo!</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.DASHBOARD)}>
                    Vai alla dashboard
                  </CButton>
                </>
              ) : (
                <>
                  <p>{`Non é stato possibile creare l'itinerario`}</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.DASHBOARD)}>
                    Vai alla dashboard
                  </CButton>
                </>
              )}
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>
    </>
  )
}

export default AddItinerary
