import React from 'react'
import AddItinerary from './views/addItinerary/AddItinerary'
import AddTag from './views/addTag/AddTag'
import AddImage from './views/addImage/AddImage'
import EditItinerary from './views/editItinerary/EditItinerary'
import ManageGameImages from './views/manageGameImages/ManageGameImages'
import RedeemCodes from './views/redeemCodes/RedeemCodes'
import Tags from './views/tags/Tags'
import AddReferrals from './views/addReferrals/AddReferrals'
import EditTag from './views/editTag/EditTag'
import Leaderboard from './views/leaderboard/Leaderboard'
import Users from './views/users/Users'
import Merchants from './views/merchants/Merchants'
import AddMerchantInvitations from './views/addMerchantInvitations/AddMerchantInvitations'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))

export const MyRoutes = {
  DASHBOARD: '/dashboard',
  ADD_ITINERARY: '/add-itinerary',
  ADD_TAG: '/add-tag',
  ADD_UPLOAD: '/add-upload',
  ADD_REFERRALS: '/add-referrals',
  ADD_MERCHANT_INVITATIONS: '/add-partner-invitations',
  MANAGE_UPLOADS: '/manage-uploads',
  EDIT_ITINERARY: '/edit-itinerary',
  EDIT_TAG: '/edit-tag',
  REDEEM_CODES: '/redeem-codes',
  TAGS: '/tags',
  USERS: '/users',
  MERCHANTS: '/partners',
  LEADERBOARD: '/leaderboard',
  LOGIN: '/login',
  REGISTER: '/register',
  NOT_FOUND: '/404',
  INTERNAL_SERVER_ERROR: '/500',
}

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: MyRoutes.DASHBOARD, name: 'Dashboard', element: Dashboard },
  { path: MyRoutes.ADD_ITINERARY, name: 'Add Itinerary', element: AddItinerary },
  {
    path: `${MyRoutes.EDIT_ITINERARY}/:itineraryId`,
    name: 'Edit Itinerary',
    element: EditItinerary,
  },
  {
    path: `${MyRoutes.EDIT_TAG}/:tagId`,
    name: 'Edit Tag',
    element: EditTag,
  },
  { path: MyRoutes.REDEEM_CODES, name: 'Redeem codes', element: RedeemCodes },
  { path: MyRoutes.TAGS, name: 'Tags', element: Tags },
  { path: MyRoutes.ADD_TAG, name: 'Add Tag', element: AddTag },
  { path: MyRoutes.ADD_UPLOAD, name: 'Add Image', element: AddImage },
  { path: MyRoutes.ADD_REFERRALS, name: 'Add Referral', element: AddReferrals },
  {
    path: MyRoutes.ADD_MERCHANT_INVITATIONS,
    name: 'Add Partner invitations',
    element: AddMerchantInvitations,
  },
  { path: MyRoutes.MANAGE_UPLOADS, name: 'Manage Images', element: ManageGameImages },
  { path: MyRoutes.LEADERBOARD, name: 'Leaderboard', element: Leaderboard },
  { path: MyRoutes.USERS, name: 'Users', element: Users },
  { path: MyRoutes.MERCHANTS, name: 'Partners', element: Merchants },
]

export default routes
