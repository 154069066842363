// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { del, get } from 'src/services/http-service'
import {
  ENDPOINT_DELETE_GAME_IMAGE,
  ENDPOINT_GET_GAME_IMAGES_NAMES,
  GAME_IMAGES_PATH,
} from 'src/variables'
import {
  CButton,
  CImage,
  CCol,
  CModal,
  CModalBody,
  CRow,
  CSpinner,
  CModalFooter,
} from '@coreui/react'

const ManageGameImages = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const userToken = useSelector((state) => state.user.token)
  const [gameImagesNames, setGameImagesNames] = useState([])
  const [selectedImageName, setSelectedImageName] = useState()
  const [deleteState, setDeleteState] = useState('not_sent')

  useEffect(() => {
    async function fetchGameImagesNames() {
      const res = await get({
        endpoint: ENDPOINT_GET_GAME_IMAGES_NAMES,
        bearerToken: userToken,
      })

      setGameImagesNames(res)
    }

    if (userToken) {
      fetchGameImagesNames()
    }
  }, [userToken])

  async function deleteImage(imageName) {
    try {
      setDeleteState('pending')
      await del({
        endpoint: ENDPOINT_DELETE_GAME_IMAGE,
        bearerToken: userToken,
        data: {
          imageName,
        },
      })
      const imageIndex = gameImagesNames.indexOf(imageName)
      let newGameImagesNames = [...gameImagesNames]
      newGameImagesNames.splice(imageIndex, 1)
      setGameImagesNames(newGameImagesNames)
      setDeleteState('success')
    } catch (err) {
      setDeleteState('failure')
    }
  }

  const gameImagesHtml = gameImagesNames.map((gameImageName) => (
    <CCol style={{ marginBottom: '25px' }} key={gameImageName} sm={12} md={6} lg={4}>
      <div onClick={() => setSelectedImageName(gameImageName)}>
        <CImage className="upload-img" src={`${GAME_IMAGES_PATH}/${gameImageName}`} />
      </div>
    </CCol>
  ))

  return (
    <>
      <CRow>{gameImagesHtml}</CRow>
      <CModal
        alignment="center"
        className="show position-fixed"
        keyboard={false}
        portal={false}
        visible={!!selectedImageName}
        onClose={() => {
          if (deleteState !== 'pending') {
            setSelectedImageName()
            setDeleteState('not_sent')
          }
        }}
      >
        <CModalBody>
          <CRow className="justify-content-center align-items-center">
            <CCol className="text-center">
              {deleteState === 'not_sent' && <span>{selectedImageName}</span>}
              {deleteState === 'pending' && <CSpinner />}
              {deleteState === 'success' && <span>Immagine cancellata con successo!</span>}
              {deleteState === 'failure' && (
                <span>{"Non é stato possibile cancellare l'immagine, riprova piú tardi"}</span>
              )}
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          {selectedImageName && (deleteState === 'not_sent' || deleteState === 'pending') && (
            <CButton
              disabled={deleteState === 'pending'}
              color="danger"
              onClick={() => deleteImage(selectedImageName)}
            >
              Cancella
            </CButton>
          )}
          <CButton
            onClick={() => {
              setSelectedImageName()
              setDeleteState('not_sent')
            }}
          >
            Chiudi
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default ManageGameImages
