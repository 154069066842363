// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import { cilAt } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CAlert,
  CButton,
  CCardText,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSpinner,
  CTabContent,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTabPane,
} from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { get, post, postUnwrapped } from 'src/services/http-service'
import {
  ENDPOINT_GET_EXPORT_USERS_CSV,
  ENDPOINT_GET_REDEEM_CODES,
  ENDPOINT_GET_USERS,
  ENDPOINT_POST_BURN_TOKENS,
  ENDPOINT_POST_SEND_REFERRAL,
  ENDPOINT_POST_SYNC_BALANCES,
} from 'src/variables'
import { object, string, number } from 'yup'
import InvitationsTab from './InvitationsTab'
import MerchantsTab from './MerchantsTab'

const Merchants = () => {
  const [selectedTab, setSelectedTab] = useState('invitations')

  return (
    <>
      <CNav variant="tabs" role="tablist">
        <CNavItem>
          <CNavLink
            active={selectedTab === 'invitations'}
            onClick={() => setSelectedTab('invitations')}
          >
            Inviti
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink
            active={selectedTab === 'merchants'}
            onClick={() => setSelectedTab('merchants')}
          >
            Venditori
          </CNavLink>
        </CNavItem>
      </CNav>
      <CTabContent>
        <CTabPane
          role="tabpanel"
          aria-labelledby="home-tab"
          visible={selectedTab === 'invitations'}
        >
          <InvitationsTab />
        </CTabPane>
        <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={selectedTab === 'merchants'}>
          <MerchantsTab />
        </CTabPane>
      </CTabContent>
    </>
  )
}

export default Merchants
