// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import { CButton, CCol, CModal, CModalBody, CModalFooter, CRow, CSpinner } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MyCard } from 'src/components/MyCard'
import { get } from 'src/services/http-service'
import { ENDPOINT_GET_TAGS } from 'src/variables'
import { useNavigate } from 'react-router-dom'
import { MyRoutes } from 'src/routes'

const Tags = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const userToken = useSelector((state) => state.user.token)
  const [tags, setTags] = useState([])
  const [selectedTag, setSelectedTag] = useState()
  const [deleteState, setDeleteState] = useState('not_sent')
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchTags() {
      const res = await get({
        endpoint: ENDPOINT_GET_TAGS,
        bearerToken: userToken,
      })

      setTags(res)
    }

    if (userToken) {
      fetchTags()
    }
  }, [userToken])

  const onTagCardClick = (tag) => {
    // const encoded = tag.id.toString('base64')
    navigate(`${MyRoutes.EDIT_TAG}/${tag.id}`)
  }

  async function deleteTag(tag) {
    // try {
    //   setDeleteState('pending')
    //   await del({
    //     endpoint: ENDPOINT_DELETE_GAME_IMAGE,
    //     bearerToken: userToken,
    //     data: {
    //       logoName,
    //     },
    //   })
    //   const imageIndex = tags.indexOf(logoName)
    //   let newTags = [...tags]
    //   newTags.splice(imageIndex, 1)
    //   setTags(newTags)
    //   setDeleteState('success')
    // } catch (err) {
    //   setDeleteState('failure')
    // }
  }

  const tagsHtml = tags.map((tag, i) => (
    <CCol style={{ marginBottom: '15px' }} key={tag.id} sm={12} md={6} lg={4}>
      <MyCard heading={tag.locales[0].name} onClick={() => onTagCardClick(tag)}>
        <CRow>
          <CCol className="text-start" sm={6}>
            Descrizione
          </CCol>
          <CCol className="text-end" sm={6}>
            {tag.locales[0].description.length > 10
              ? `${tag.locales[0].description.substring(0, 10)}...`
              : tag.locales[0].description}
          </CCol>
        </CRow>
        <CRow>
          <CCol className="text-start" sm={6}>
            Lingue
          </CCol>
          <CCol className="text-end" sm={6}>
            {tag.locales.map((locale, i) => locale.locale).join(', ')}
          </CCol>
        </CRow>
      </MyCard>
    </CCol>
  ))

  return (
    <>
      <CRow>{tagsHtml}</CRow>
      <CModal
        alignment="center"
        className="show position-fixed"
        keyboard={false}
        portal={false}
        visible={!!selectedTag}
        onClose={() => {
          if (deleteState !== 'pending') {
            setSelectedTag()
            setDeleteState('not_sent')
          }
        }}
      >
        <CModalBody>
          <CRow className="justify-content-center align-items-center">
            <CCol className="text-center">
              {deleteState === 'not_sent' && <span>{selectedTag}</span>}
              {deleteState === 'pending' && <CSpinner />}
              {deleteState === 'success' && <span>Immagine cancellata con successo!</span>}
              {deleteState === 'failure' && (
                <span>{"Non é stato possibile cancellare l'immagine, riprova piú tardi"}</span>
              )}
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter>
          {selectedTag && (deleteState === 'not_sent' || deleteState === 'pending') && (
            <CButton
              disabled={deleteState === 'pending'}
              color="danger"
              onClick={() => deleteTag(selectedTag)}
            >
              Cancella
            </CButton>
          )}
          <CButton
            onClick={() => {
              setSelectedTag()
              setDeleteState('not_sent')
            }}
          >
            Chiudi
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default Tags
