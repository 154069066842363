// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import { CButton, CCol, CForm, CFormInput, CModal, CModalBody, CRow, CSpinner } from '@coreui/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MyRoutes } from 'src/routes'
import { post } from 'src/services/http-service'
import {
  ENDPOINT_POST_CREATE_MERCHANT_INVITATIONS,
  ENDPOINT_POST_CREATE_REFERRALS,
} from 'src/variables'
import { v4 } from 'uuid'

const AddMerchantInvitations = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const userToken = useSelector((state) => state.user.token)
  const [invitationsAmount, setInvitationsAmount] = useState()
  const [generateState, setGenerateState] = useState('not_sent')
  const formId = v4()

  const navigate = useNavigate()

  async function submit(e) {
    e.preventDefault()
    setGenerateState('pending')

    try {
      await post({
        endpoint: ENDPOINT_POST_CREATE_MERCHANT_INVITATIONS,
        bearerToken: userToken,
        data: {
          count: invitationsAmount,
        },
      })
      setGenerateState('success')
    } catch (err) {
      console.log(err)
      setGenerateState('failure')
    }
  }

  return (
    <>
      <CForm id={formId} onSubmit={submit}>
        <CFormInput
          type="number"
          min={1}
          name="invitationsAmount"
          id="invitationsAmount"
          onInput={(e) => setInvitationsAmount(e.target.value)}
        />

        <CButton
          disabled={!invitationsAmount || generateState !== 'not_sent'}
          className="image-upload-form-submit-button mt-3"
          form={formId}
          type="submit"
        >
          Genera
        </CButton>
      </CForm>
      <CModal
        alignment="center"
        className="show position-fixed"
        keyboard={false}
        portal={false}
        visible={generateState !== 'not_sent'}
      >
        <CModalBody>
          <CRow className="justify-content-center align-items-center">
            <CCol className="text-center">
              {generateState === 'pending' ? (
                <CSpinner />
              ) : generateState === 'success' ? (
                <>
                  <p>Codici generati con successo!</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.MERCHANTS)}>
                    Vai ai codici
                  </CButton>
                </>
              ) : (
                <>
                  <p>{'Non é stato possibile generare i codici'}</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.DASHBOARD)}>
                    Vai alla dashboard
                  </CButton>
                </>
              )}
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>
    </>
  )
}

export default AddMerchantInvitations
