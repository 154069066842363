// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
import { CButton, CCol, CForm, CFormInput, CModal, CModalBody, CRow, CSpinner } from '@coreui/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { MyRoutes } from 'src/routes'
import { post } from 'src/services/http-service'
import { ENDPOINT_POST_UPLOAD_GAME_IMAGE } from 'src/variables'
import { v4 } from 'uuid'

const AddImage = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const userToken = useSelector((state) => state.user.token)
  const [fileToUpload, setFileToUpload] = useState()
  const [uploadState, setUploadState] = useState('not_sent')
  const formId = v4()

  const navigate = useNavigate()

  function formOutput2UploadBody(image) {
    const bodyFormData = new FormData()
    bodyFormData.append('fileImage', image)

    return bodyFormData
  }

  async function submit(e) {
    e.preventDefault()
    const reqBody = formOutput2UploadBody(fileToUpload)
    console.log(reqBody)
    setUploadState('pending')

    // console.log(reqBody)
    try {
      await post({
        endpoint: ENDPOINT_POST_UPLOAD_GAME_IMAGE,
        bearerToken: userToken,
        data: reqBody,
        useFormData: true,
      })
      setUploadState('success')
    } catch (err) {
      console.log(err)
      setUploadState('failure')
    }
  }

  // return <ItineraryForm onChange={setItinerary} onSubmit={submit}></ItineraryForm>
  return (
    <>
      <CForm id={formId} onSubmit={submit}>
        {/* <Controller
          render={({ field }) => {
            return (
              <CFormInput type="file" name="fileImage" id="fileImage" onInput={field.onChange} />
            )
          }}
          name="cost"
          control={control}
        /> */}
        <CFormInput
          type="file"
          name="fileImage"
          id="fileImage"
          onInput={(e) => setFileToUpload(e.target.files[0])}
        />

        <CButton
          disabled={!fileToUpload || uploadState !== 'not_sent'}
          className="image-upload-form-submit-button mt-3"
          form={formId}
          type="submit"
        >
          Upload
        </CButton>
      </CForm>
      <CModal
        alignment="center"
        className="show position-fixed"
        keyboard={false}
        portal={false}
        visible={uploadState !== 'not_sent'}
      >
        <CModalBody>
          <CRow className="justify-content-center align-items-center">
            <CCol className="text-center">
              {uploadState === 'pending' ? (
                <CSpinner />
              ) : uploadState === 'success' ? (
                <>
                  <p>Upload effettuato con successo!</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.DASHBOARD)}>
                    Vai alla dashboard
                  </CButton>
                </>
              ) : (
                <>
                  <p>{"Non é stato possibile effettuare l'upload"}</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.DASHBOARD)}>
                    Vai alla dashboard
                  </CButton>
                </>
              )}
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>
    </>
  )
}

export default AddImage
