// import { CTableRow } from '@coreui/react'
// import { CChartLine } from '@coreui/react-chartjs'
// import { getStyle, hexToRgba } from '@coreui/utils'
// import { cilTrash } from '@coreui/icons'
// import CIcon from '@coreui/icons-react'
import { CButton, CCol, CModal, CModalBody, CRow, CSpinner } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { GameFields } from 'src/components/forms/gameFields'
import { TagForm } from 'src/components/forms/tagForm'
import { MyRoutes } from 'src/routes'
import { del, get, patch, post } from 'src/services/http-service'
import {
  ENDPOINT_DELETE_ITINERARY,
  ENDPOINT_GET_TAG_COMPLETE,
  ENDPOINT_PATCH_UPDATE_TAG,
} from 'src/variables'

const EditTag = () => {
  // const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
  const userToken = useSelector((state) => state.user.token)
  const [tag, setTag] = useState()
  const [editRequestState, setCreateRequestState] = useState('not_sent')
  // const [isDeleteRequest, setIsDeleteRequest] = useState(false)
  // const [deleteRequestState, setDeleteRequestState] = useState('not_sent')

  const navigate = useNavigate()
  const { tagId } = useParams()

  useEffect(() => {
    async function fetchTagComplete() {
      // const decoded = atob()

      const res = await get({
        endpoint: `${ENDPOINT_GET_TAG_COMPLETE}/${tagId}`,
        bearerToken: userToken,
      })

      console.log('edit tag fetch: ', res)
      setTag(fetchResponse2FormInput(res))
    }

    fetchTagComplete()
  }, [])

  async function submit() {
    const reqBody = formOutput2EditBody(tag)
    console.log('submit: ', reqBody)

    setCreateRequestState('pending')

    try {
      const res = await patch({
        endpoint: ENDPOINT_PATCH_UPDATE_TAG,
        bearerToken: userToken,
        data: reqBody,
      })
      console.log('res: ', res)
      setCreateRequestState('success')
    } catch (err) {
      // console.log('err: ', err)
      setCreateRequestState('failure')
    }
  }

  // function gameConfiguration2Record(configuration, gameType) {
  //   const { id, title, description, hint, locale, isDefault, ...config } = configuration

  //   console.log('config: ', config)
  //   return {
  //     id,
  //     title,
  //     description,
  //     hint,
  //     config: JSON.stringify(
  //       GameFields[gameType].reduce(
  //         (acc, field) => ({
  //           ...acc,
  //           [field.name]: field.name === 'reward' ? Number(config[field.name]) : config[field.name],
  //         }),
  //         {},
  //       ),
  //     ),
  //     locale,
  //     isDefault,
  //   }
  // }

  function formOutput2EditBody(tag) {
    let { id, logoName, order, locales } = tag

    const editBody = {
      id,
      logoName,
      order,
      locales,
    }

    return editBody
  }
  function fetchResponse2FormInput(tag) {
    const { createdAt, updatedAt, deletedAt, ...formInput } = tag

    formInput.locales = formInput.locales.map((locale) => {
      const { createdAt, updatedAt, deletedAt, ...localeRest } = locale

      return localeRest
    })

    return formInput
  }

  return (
    <>
      {/* <CButton
        color="danger"
        className="accordion-remove-button"
        onClick={toggleDeleteItineraryRequest}
      >
        <CIcon icon={cilTrash} />
      </CButton> */}
      {tag && <TagForm initialValue={tag} onChange={setTag} onSubmit={submit} />}
      <CModal
        alignment="center"
        className="show position-fixed"
        keyboard={false}
        portal={false}
        visible={editRequestState !== 'not_sent'}
      >
        <CModalBody>
          <CRow className="justify-content-center align-items-center">
            <CCol className="text-center">
              {editRequestState === 'pending' ? (
                <CSpinner />
              ) : editRequestState === 'success' ? (
                <>
                  <p>Tag modificato con successo!</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.DASHBOARD)}>
                    Vai alla dashboard
                  </CButton>
                </>
              ) : (
                <>
                  <p>{`Non é stato possibile modificare il tag`}</p>
                  <CButton color="primary" onClick={() => navigate(MyRoutes.DASHBOARD)}>
                    Vai alla dashboard
                  </CButton>
                </>
              )}
            </CCol>
          </CRow>
        </CModalBody>
      </CModal>
      {/* <CModal
        alignment="center"
        className="show position-fixed"
        keyboard={false}
        portal={false}
        visible={isDeleteRequest}
      >
        <CModalBody>{deleteModalBody}</CModalBody>
      </CModal> */}
    </>
  )
}

export default EditTag
