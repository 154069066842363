import moment from 'moment'
import React, { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import { MyRoutes } from './routes'
import './scss/style.scss'
import 'moment/locale/it'
import { post } from './services/http-service'
import { login } from './slices/userSlice'
import { AUTH_TOKEN_KEY, ENDPOINT_VALIDATE_TOKEN } from './variables'

moment.locale('it')

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const dispatch = useDispatch()
  const userToken = useSelector((state) => state.user.token)
  useEffect(() => {
    async function validateToken() {
      const authToken = localStorage.getItem(AUTH_TOKEN_KEY)

      if (authToken) {
        try {
          const validationRes = await post({
            endpoint: ENDPOINT_VALIDATE_TOKEN,
            bearerToken: authToken,
          })
          dispatch(
            login({
              id: validationRes.id,
              email: validationRes.email,
              username: validationRes.username,
              token: authToken,
            }),
          )

          console.log('access token valid')
        } catch (err) {
          alert('Accesso scaduto')
        }
      }
    }

    validateToken()
  }, [])

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path={MyRoutes.LOGIN} name="Login Page" element={<Login />} />
          <Route exact path={MyRoutes.REGISTER} name="Register Page" element={<Register />} />
          <Route exact path={MyRoutes.NOT_FOUND} name="Page 404" element={<Page404 />} />
          <Route
            exact
            path={MyRoutes.INTERNAL_SERVER_ERROR}
            name="Page 500"
            element={<Page500 />}
          />
          <Route
            path="*"
            name="Home"
            element={userToken ? <DefaultLayout /> : <Navigate to={MyRoutes.LOGIN} />}
          />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
